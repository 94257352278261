import React, { FC } from "react";
import { Container } from "@mui/system";
import classNames from "classnames";
import logo from "assets/vortex-siege-logo.png";
import discordLogo from "assets/discord-logo.png";
import styles from "./Main.module.scss";
import { Button } from "@mui/material";

interface MainProps {}

const Main: FC<MainProps> = () => {
  return (
    <div className={classNames(styles.Main, styles.view)}>
      <Container>
        <img src={logo} alt="logo" className={styles.logo} />
        <div className={styles.externalLinksBox}>
          <iframe
            src="https://store.steampowered.com/widget/3029050/"
            frameBorder="0"
            width="646"
            height="190"
            title="Vortex Siege on Steam"
          />
          <Button
            className={styles.button}
            variant="outlined"
            href="https://discord.gg/DQhNK8Bvrt"
          >
            <img
              src={discordLogo}
              alt="Discord logo"
              className={styles.discordLogo}
            />
            Join Our Discord!
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Main;
